<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="flex flex-col h-screen p-4 items-center">
    <div class="py-16" />
    <div
      class="bg-white overflow-hidden shadow rounded-lg md:max-w-3xl"
      id="main"
      v-if="false"
    >
      <div class="px-4 py-5 sm:p-6 flex-grow-0">
        <div>
          <span class="font-medium text-xl"> Маленька моя Віка ❤️, </span>
          <br />
          Вітаю тебе з цим прекрасним найкрасивішої частини суспільства -
          міжнародним жіночим днем. 🌷<br />
          Весняного настрою по життю, позитивних емоцій, а про решту я подбаю 😊
        </div>

        <div>
          <br />
          Нажаль сайт SPA
          <a class="text-indigo-600 underline" href="http://sharel.com.ua/"
            >приліг</a
          >
          і дістати сертифікат не вийшло, а лишити тебе без подарунку не міг,
          тому я
          <a
            class="text-indigo-600 underline"
            href="promo.pdf"
            download="promo.pdf"
            >зробив свій сертифікат
          </a>
          🙃 <br /><br />

          <div class="text-right font-medium">Твій Марк 😘</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {}
};
</script>

<style>
html {
  background-image: url("assets/back.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
}
</style>
